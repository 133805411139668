import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { UserService } from 'src/app/core';
import { VideoPlayerService } from 'src/app/core/services/videoPlayerService';
import { jwPlayerService } from 'src/app/views/jw-video-player/jwplayer.servies';
import { Analyticsstreaminglog, CatlougeTypeConstants } from "src/app/core/constants/index";


declare var videojs: any;
declare var $: any;
declare var google;

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal]
})

export class LiveComponent implements OnInit {

  @Input() streamInfo: any;
  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: string;
  title: string = '';
  videoPlayUrl: any = '';

  userUnique: string = 'NA';
  currentPlayingCode: string = '';
  isPlayed: number = 0;

  // log object
  currentPlayingCharegCode: string = '';
  currentPlayingMongoId: string = '';
  currentPlayingContentAvailability: string = '';
  currentPlayingThumb: string = '';
  currentPlayingSec: string = '';
  currentPlayingMin: string = '';
  currentPlayingHour: string = '';
  currentPlayingDuration: string = '';
  currentPlayingGenreText: string = '';
  currentPlayingGenre: string = '';
  currentPlayingLanguages: string = '';
  currentPlayingSys: string = '';
  currentPlayingShowID: string = '';
  currentPlayingSeasonId: string = '';
  currentPlayingName: string = '';
  @ViewChild("showErrorMsg") showErrorMsg;
  @ViewChild("closeShowErrorMsg") closeShowErrorMsg;

  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationStrategy,
    public VideoPlayerService: VideoPlayerService,
    public UserService: UserService,
    public jwPlayerService: jwPlayerService,
    public spinner: SpinnerVisibilityService,
    public router: Router
  ) {

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.mobileBrowserBackbutton();

    });
    window.stop();
    document.addEventListener('contextmenu', event => event.preventDefault());
  }

  mobileBrowserBackbutton() {
    this.sendAnalyticsVideoEndLog();

    this.disableModal();
    if (this.playermodal)
      this.playermodal.dispose();

    this.playermodal = '';
    return false;
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.sessionId = localStorage.getItem('sessionId');

    if (Object.keys(this.userInfo.result).length > 0) {
      if (this.userInfo.result.register_by == 'mobile') {
        this.userUnique = this.userInfo.result.mobile;
      } else {
        this.userUnique = this.userInfo.result.emailaddress;
      }
    }
    // this.streamInfo = this.streamInfo;
    // try {
    //   this.currentPlayingCode = this.streamInfo.code;

    // } catch (err) {

    // }


    this.streamInfo = this.streamInfo;
    console.log(this.streamInfo);
    // this.currentPlayingCode = this.streamInfo.recently_watched.code;
    // if(this.streamInfo['seek_bar_data'] == undefined){
    //   this.streamInfo['seek_bar_data'] = {'code': ''};
    //this.streamInfo['seek_bar_data'].code = this.streamInfo.recently_watched.code;
    // }



    console.log(this.streamInfo);

    try {
      this.currentPlayingCode = this.streamInfo.code;//== undefined ? this.streamInfo.seek_bar_data.code : this.streamInfo.recently_watched.code;
      this.currentPlayingMongoId = this.streamInfo.asset_mongo_id;//== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingName = this.streamInfo.name;//== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingCharegCode = this.streamInfo.charge_code;//== undefined ? this.streamInfo.charge_code : this.streamInfo.recently_watched.charge_code;
      this.currentPlayingContentAvailability = this.streamInfo.content_availability;//== undefined ? this.streamInfo.content_availability : this.streamInfo.recently_watched.content_availability;
      this.currentPlayingThumb = this.streamInfo.thumbnail;// == undefined ? this.streamInfo.thumbnail : this.streamInfo.recently_watched.thumbnail;
      this.currentPlayingSec = this.streamInfo.sec;// == undefined ? this.streamInfo.recently_watched.sec == undefined ? this.streamInfo.sec : this.streamInfo.recently_watched.sec : this.streamInfo.recently_watched.asset.sec;

      this.currentPlayingMin = this.streamInfo.min;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.min == undefined ? this.streamInfo.min : this.streamInfo.recently_watched.min : this.streamInfo.recently_watched.asset.min;
      this.currentPlayingHour = this.streamInfo.hours;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingDuration = this.streamInfo.duration;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingGenreText = this.streamInfo.genre_text;//== undefined ? this.streamInfo.genre_text : this.streamInfo.recently_watched.genre_text;
      this.currentPlayingGenre = Object.keys(this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0] : '';// this.streamInfo.recently_watched.genre == undefined ? Object.keys( this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0]: '' : this.streamInfo.recently_watched.genre[0] ;
      this.currentPlayingLanguages = this.streamInfo.languages;//== undefined ? this.streamInfo.languages : this.streamInfo.recently_watched.languages;
      this.currentPlayingSys = this.streamInfo.synopsis;//== undefined ? this.streamInfo.synopsis : this.streamInfo.recently_watched.synopsis;
      this.currentPlayingShowID = this.streamInfo.show_id;// == undefined ? this.streamInfo.show_id : this.streamInfo.recently_watched.show_id;
      this.currentPlayingSeasonId = this.streamInfo.season_id;//== undefined ? this.streamInfo.season_id : this.streamInfo.recently_watched.season_id;

    } catch (e) {
      console.log(e)
    }

    this.playVideo();
    this.sendAnalyticsVideoEndLog();

  }

  playVideo() {
    let videoStreamObj = {
      "nmode": "WIFI",
      "pushId": '',
      "ct": "1",
      "build": 10000,
      "charge_code": this.currentPlayingCode,
      "appv": "1.0.00",
      "push_date": "",
      "air": "airip"
    };

    let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);

    this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(playObj => {

      if (playObj.result == undefined || playObj.result == '') {

        if (playObj.error_string) {
          this.router.navigate(['subscribe/choseplan'], { queryParams: { 'code': this.currentPlayingCode } });
        }
        this.disableModal();
        return false;
      }

      if (
        playObj.cloud_front &&
        Object.keys(playObj.cloud_front).length > 0
      ) {
        if (playObj.cloud_front.cloud_url != "" && playObj.cloud_front.cloudfront_policy != "" && playObj.cloud_front.cloudfront_signature != "") {
          this.videoPlayUrl = playObj.cloud_front.cloud_url;
          this.setCookie(
            "CloudFront-Key-Pair-Id",
            playObj.cloud_front.cloudfront_key_pair_id.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Policy",
            playObj.cloud_front.cloudfront_policy.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Signature",
            playObj.cloud_front.cloudfront_signature.trim(),
            1
          );
        } else {
          this.videoPlayUrl = playObj.result;
          //this.showErrorMsg.nativeElement.click();
        }
      } else {
        this.videoPlayUrl = playObj.result;
      }

      //this.videoPlayUrl = playObj.result;//'https://digilive.nexg.tv/live/salvationtv.smil/playlist.m3u8?sf=net';//
      //console.log(this.videoPlayUrl);
      if (playObj.assetData.name != undefined)
        this.title = playObj.assetData.name;
      else
        this.title = playObj.assetData.channel_name;

      let advertisement_url = playObj.assetData.advertisement_url;

      let new_source = {
        sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
        controls: true,
        autoplay: true,
        withCredentials: true,
        crossDomain: true,
        techOrder: ["html5"],
        html5: {
          hls: {
            overrideNative: true,
            cacheEncryptionKeys: true,
          },
        },
        plugins: {
          dashHlsBitrateSwitcher: {
            support: "both",
          },
          settingsMenu: {
            items: [
              "AudioTrackButton",
              "ChaptersButton",
              "SubsCapsButton",
              "PlaybackRateMenuButton",
              "RatesButton",
            ],
            languages: {
              settings: "Settings",
              loading: "Loading",
              back: "Back",
              captions_off: "Captions Off",
              default_audio: "Default Audio",
              audio: "Audio",
              subtitles: "Subtitles",
              chapters: "Chapters",
              speed: "Speed",
              quality: "Quality",
            },
          },
        },
        crossOrigin: "anonymous",
        liveui: true,
        playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        nativeControlsForTouch: false
      };

      this.playermodal = videojs('player_one', new_source);
      videojs.options.hls.overrideNative = true;
      videojs.options.html5.nativeAudioTracks = false;
      videojs.options.html5.nativeTextTracks = false;

      let imaOptions = {
        adTagUrl: advertisement_url,
        disableAdControls: true,
        id: 'videoAds',
      };

      $(".vjs-play-control").after($(".vjs-live-control"));

      this.playermodal.ima(imaOptions);
      google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);

      var startEvent = 'click';
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        startEvent = 'touchend';
      }
      let _this = this;
      this.playermodal.one(startEvent, function () {
        _this.playermodal.ima.initializeAdDisplayContainer();
      });

      this.playermodal.on('adend', function () {
        console.log('playermodal adend');
        // 3. Re-enable volume, restart video from beginning
        _this.playermodal.play();

      });
      this.playermodal.on('play', (event) => {
        if (this.isPlayed > 0)
          this.playVideo();
        this.sendAnalyticsVideoEndLog();

        this.isPlayed = 1;
      });
      this.playermodal.on('pause', (event) => {
        this.sendAnalyticsVideoEndLog();
      });

      this.playermodal.poster(playObj.assetData.image);

      // this.playermodal.nuevo({
      //   videoInfo: true,
      //   infoTitle: this.title,
      //   infoIcon: "",
      //   liveTimeout: 10,
      //   qualityMenu: true,
      //   pipButton: false,
      //   //rateMenu:false,
      //   settingButton: true,
      //  // zoomMenu: false,
      //   buttonRewind: false,
      //   buttonForward: false,
      //   poster: playObj.assetData.image,
      //   errordisplay: false,
      //   shareMenu: false,
      //   contextMenu: false
      // });


      $('.player_one-dimensions.vjs-fluid').css('height', `${window.innerHeight}px`);

      $(window).bind('orientationchange', function (event) {
        let height = window.innerHeight;
        $('.player_one-dimensions.vjs-fluid').css('height', `${height}px`);
      });

      $(window).resize(function () {
        let height = window.innerHeight;
        $('.player_one-dimensions.vjs-fluid').css('height', `${height}px`);
      });

      this.playermodal.ready((event) => {

        //event.preventDefault();
        var topTitle = '<span class="vjs-info vjs-info-top" style="pointer-events: none;"><span class="vjs-text"><span class="vjs-ttl">' + this.title + '</span></span></span>';
        $(".ima-ad-container").after(topTitle);

        $('.vjs-text').append('<i class="fa fa-arrow-left vjs-ttl_btn" id="vjs-ttl_btn"></i>');

        let buttonfwd = document.getElementById("vjs-ttl_btn");

        buttonfwd.ontouchstart = (event) => {

          this.sendAnalyticsVideoEndLog();
          event.stopPropagation();

          event.preventDefault();

          this.disableModal();
          if (this.playermodal)
            this.playermodal.dispose();

          this.playermodal = '';

          return false;
        };

        buttonfwd.onclick = (event) => {
          this.sendAnalyticsVideoEndLog();
          event.stopPropagation();

          event.preventDefault();

          this.disableModal();
          if (this.playermodal)
            this.playermodal.dispose();

          //$('body').removeClass('vjs-full-window');
          this.playermodal = '';

          return false;
        }


        let self = this;
        this.playermodal.on('timeupdate', function () {

        });


      });
    });
  }

  sendAnalyticsVideoEndLog() {

    let playLogReqdata = Analyticsstreaminglog.logData;
    playLogReqdata.platform = "web";
    playLogReqdata.code = this.currentPlayingCode;
    playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
    playLogReqdata.play_url = this.videoPlayUrl;
    playLogReqdata.asset_code = this.currentPlayingCode;
    playLogReqdata.category = this.currentPlayingCode;

    // playLogReqdata['show_id'] = this.streamInfo.recently_watched.show_id;
    // playLogReqdata['season_id'] = this.streamInfo.recently_watched.season_id;
    playLogReqdata.stop = Math.round(this.playermodal.currentTime());

    playLogReqdata['duration'] = Math.round(this.playermodal.duration());
    let Playerduration = Math.round(this.playermodal.duration());

    console.log(Playerduration);
    playLogReqdata.charge_code = this.currentPlayingCharegCode;
    playLogReqdata['last_video_play_id'] = this.currentPlayingMongoId;//this.streamInfo.asset_mongo_id;
    playLogReqdata['release_date'] = this.streamInfo.release_date ? this.streamInfo.release_date : '';
    playLogReqdata['name'] = this.currentPlayingName;//this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
    playLogReqdata['cloud_image'] = this.currentPlayingThumb;//this.streamInfo.thumbnail;
    playLogReqdata['content_availability'] = this.currentPlayingContentAvailability;//this.streamInfo.content_availability;
    playLogReqdata['asset_mongo_id'] = this.currentPlayingMongoId;

    playLogReqdata['sec'] = this.currentPlayingSec;
    playLogReqdata['min'] = this.currentPlayingMin;
    playLogReqdata['hours'] = this.currentPlayingHour;
    playLogReqdata['genre_text'] = this.currentPlayingGenreText;
    playLogReqdata['languages'] = this.currentPlayingLanguages;
    playLogReqdata['genre'] = this.currentPlayingGenre;
    playLogReqdata['synopsis'] = this.currentPlayingSys;

    if (playLogReqdata['duration'] == undefined || playLogReqdata['duration'] == 'undefined') {
      playLogReqdata['duration'] = this.currentPlayingDuration;
    }

    if (playLogReqdata.stop > playLogReqdata['duration']) {
      playLogReqdata.stop = playLogReqdata['duration'] - 1;
    }

    console.log('duration ' + playLogReqdata['duration']);




    console.log('playLogReqdata.stop ' + playLogReqdata.stop);
    console.log('playLogReqdata.duration ' + playLogReqdata['duration']);


    playLogReqdata.start = 0;
    playLogReqdata.mobile = this.userInfo.result.mobile;

    playLogReqdata['type'] = this.streamInfo.type;
    playLogReqdata['uid'] = this.sessionId;

    if (Math.round(this.playermodal.currentTime()) == 0) {
      return false;
    }


    setTimeout(() => { this.spinner.hide(); }, 10);
    console.log(playLogReqdata);
    let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);


    let totalDuration = Math.round(this.playermodal.duration());//this.playermodal.duration();
    let playedDuration = Math.round(this.playermodal.currentTime());//this.playermodal.currentTime();
    console.log(urlEncodedPlayData);
    if (((playedDuration * 100) / totalDuration) < 98) {
      this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res => {

      }));
    }

    let cs_uri_query = this.videoPlayUrl.split('?')[1];
    let cs_uri_stem = this.videoPlayUrl.split('?')[0];
    let videoLogData = {
      "x-app": this.streamInfo.type == 'livetv' ? 'livetv' : 'vod',
      "x-event": 'stop',
      "sc-bytes": Math.round(this.playermodal.currentTime()),
      "c-user-agent": "platform/web/PlayerVersion-2.7.3",
      "x-duration": this.playermodal.currentTime(),
      "x-sname": playLogReqdata['name'],
      "cs-uri-query": cs_uri_query ? encodeURIComponent(cs_uri_query.toString()) : "",
      "cs-uri-stem": cs_uri_stem
    }


    setTimeout(() => { this.spinner.hide(); }, 10);
    let urlEncodedEncodeParams = this.UserService.convertoJSONToUrlEncoded(videoLogData);
    this.jwPlayerService.analyticsLog(urlEncodedEncodeParams).subscribe((res => { }));



  }
  disableModal() {
    document.querySelector("ngb-modal-window").classList.remove("show");
    document.querySelector("ngb-modal-backdrop").classList.remove("show");
    document.getElementById('nexgTV-app').click();
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
    document.querySelectorAll(".d-block").forEach(el => el.remove())
    document.body.style.overflow = "scroll";
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  removeErrorBox() {
    this.closeShowErrorMsg.nativeElement.click();
    setTimeout(() => {
      this.disableModal();
      if (this.playermodal) this.playermodal.dispose();
      this.playermodal = "";
    }, 50);
  }

}
