import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { AccountService } from '../../account/account.service';
declare var $: any;
@Component({
  selector: 'app-chose-plan',
  templateUrl: './chose-plan.component.html',
  styleUrls: ['./chose-plan.component.css']
})
export class ChosePlanComponent implements OnInit {
  radioStatus: boolean;
  selectedRentPayment: boolean;
  groupPack: any =[];
  fristPack: any;
  grouplength: any;
  noPackFound: any;

  constructor(private apiService: AccountService, private userService: UserService, private route: ActivatedRoute,
    public router: Router, public formBuilder: FormBuilder,
    private googleEvent: EventTrackingService) {

      $("body").css("overflow-y", "scroll");
    this.addPromoForm = this.formBuilder.group({
      'promocode': ['']
    });
  }
  details_error: string = '';
  packs: any = [];
  rentedPacks: any = [];
  selectedChargeCode = '';
  selectedRentChargeCode = '';
  chargeCode: string = '';
  paymentArray: any = {};
  payButtonText: string = 'PAY NOW';
  searchSubscriber: any;
  userinfo: any;
  userUnique: string = 'NA';
  selected_payment_gateway: string = '';
  continuePressed: boolean = false;
  rentContinuePressed: boolean = false;
  addPromoForm: FormGroup;
  promocode_error: string = '';
  catlogue = CatlougeTypeConstants.catlogue;
  @ViewChild('add_promocodesuccess') add_promocodesuccess;
  @ViewChild('closePromoButton') closePromoButton;
  selectedIndex: number = 0;

  ngOnInit() {
    document.body.scrollTop = 0;
    if (localStorage.getItem('isAuthenticated') == undefined) {
      this.router.navigate(['/']);
    }
    this.userinfo = JSON.parse(localStorage.getItem('userInfo'));


    if (Object.keys(this.userinfo.result).length > 0) {
      if (this.userinfo.result.register_by == 'mobile') {
        this.userUnique = this.userinfo.result.mobile;
      } else {
        this.userUnique = this.userinfo.result.emailaddress;
      }
    }


  }
  ngAfterContentInit() {
    this.chargeCode = '';

    if (localStorage.getItem('isAuthenticated') == undefined || localStorage.getItem('isAuthenticated') == 'false') {
      this.router.navigate(['/']);
      return;
    } else {
      this.getPlanDetail();
    }

  }
  getPlanDetail() {
    this.searchSubscriber = this.route.queryParams.subscribe(params => {
      this.chargeCode = params['code'] == undefined ? '' : params['code'];
    });

    let requestData = { catlogue: CatlougeTypeConstants.catlogue, code: this.chargeCode, platform: 'web' };
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(requestData);
    //this.apiService.postData('getpackv2', urlEncodedBodyData).subscribe((data: any) => {
    this.apiService.postData('getpacksbygroup', urlEncodedBodyData).subscribe((data: any) => {
      // console.log(data.groupPack.record);
      
      if (data.error_code == '200') {
        this.noPackFound = data.error_string;
        this.groupPack = data.result.groupPack;
        this.grouplength = this.groupPack.length;
        console.log('this.groupPack',this.groupPack);
        this.fristPack = this.groupPack[0].packs[0];
        console.log('this.fristPack', this.fristPack);
        // if (this.fristPack.length > 0) {
          this.selectedRentChargeCode = this.fristPack.PM_CHARGECODE;
            // this.onRentItemChange(this.fristPack.PM_CHARGECODE, this.fristPack.PM_PRICE, 0);
          // }

        // this.rentedPacks = data.groupPack.rented;
        // this.packs = data.groupPack.record;

        // console.log('this.rentedPacks', this.rentedPacks);
        // if (this.rentedPacks.length > 0) {
        //   this.onRentItemChange(this.rentedPacks[0].PM_CHARGECODE, this.rentedPacks[0].PM_PRICE, 0);
        // }

        // // this.packs = data.groupPack.record;
        // if (this.packs.length > 0) {
        //   this.onItemChange(this.packs[1].PM_CHARGECODE, this.packs[1].PM_PRICE);
        // }



        // if (data.groupPack.rented != undefined) {
        // if (data.groupPack.rented) {
        // this.rentedPacks = data.groupPack.rented;
        // console.log('this.rentedPacks', this.rentedPacks);
        // if(this.rentedPacks.length > 0){
        //   this.onRentItemChange(this.rentedPacks[0].PM_CHARGECODE, this.rentedPacks[0].PM_PRICE, 0);
        // }

        // }

        // }


      } else {
        this.details_error = data.error_string;
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
      },
      () => {
      }
    );
  }

  strToLower(str: string) {
    if (str)
      return str.toLowerCase().trim();
    else
      return str;
  }
  trimString(str: string) {
    if (str)
      return str.trim();
    else
      return str;
  }

  planName(planname: string) {
    let pack_name = '';
    switch (planname) {

      case "weekly_pack":
        pack_name = 'WEEKLY PLAN';
        break;
      case "monthly_pack":
        pack_name = 'MONTHLY PLAN';
        break;
      case "yearly_pack":
        pack_name = 'YEARLY PLAN';
        break;
      default:
        pack_name = planname;
        break;
    }

    return pack_name;

  }
  continueButtons(value, price: any, index) {
    this.paymentArray = {};
    $(".selectedRentPayment").prop("checked", false);
    // $("input[name=selectedRentPayment]").removeAttr('checked');
    $('.make_payment').addClass('active');
    // this.onRentItemChange(value, price, index);
    
    this.rentContinuePressed = true;

  }
  continueButton() {
    this.paymentArray = {};
    $('.selectedPayment').prop("checked", false);
    $('.make_payment').addClass('active');
    this.continuePressed = true;
    // window.scroll(0,document.getElementById('_subscription_amount').offsetHeight);
  }

  countObjectKey(obj) {
    //  console.log(obj);
    return Object.keys(obj).length;
  }

  // onItemChange(value, price: any) {
  //   console.log(" Value is : ", value);
  //   console.log(" price is : ", price);
  //   this.continuePressed = false;
  //   this.selectedChargeCode = value;
  //   this.paymentArray = {};


  //   let planCodeLabel = 'Buying from my account';
  //   if (this.chargeCode) {
  //     planCodeLabel = this.chargeCode;
  //   }

  //   this.googleEvent.eventEmitter('cart_selection', 'cart_selection', this.userUnique + "-Selected Plan-" + price * 1, 0, this.userUnique, this.userUnique + "-Selected Plan-" + price * 1);

  //   // this.googleEvent.eventEmitter('Selected Plan - ' + price * 1, "Cart_Selection", this.userUnique + '-' + planCodeLabel, price * 1);
  // }
  onRentItemChange(value, price: any, index) {
    console.log(" Value is : ", value);
    console.log(" price is : ", price);
    this.rentContinuePressed = false;
    this.selectedRentChargeCode = value;
    this.selectedIndex = index;
    this.paymentArray = {};


    let planCodeLabel = 'Buying from my account';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('cart_selection', 'cart_selection', this.userUnique + "-Selected Plan-" + price * 1, 0, this.userUnique, this.userUnique + "-Selected Plan-" + price * 1);

    // this.googleEvent.eventEmitter('Selected Plan - ' + price * 1, "Cart_Selection", this.userUnique + '-' + planCodeLabel, price * 1);
  }
  onPaymentSelected(price, detail, payment_gateway) {
    this.details_error = "";

    this.paymentArray = {
      'amount': price,
      'ActualAmount': price,
      'charge_code': this.chargeCode,
      'pack_code': detail.PM_CHARGECODE,
      'validity': detail.PM_VALIDITY,
      'catlogue': CatlougeTypeConstants.catlogue,
      'plateform': 'web'
    }

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.selected_payment_gateway = payment_gateway;

    this.googleEvent.eventEmitter('payment_gateway_selection', 'payment_gateway_selection', this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1, 0, this.userUnique, this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1);
    // this.googleEvent.eventEmitter(payment_gateway, "Payment Gateway_Selection", this.userUnique + '-' + planCodeLabel + '-Amount-' + price * 1, price * 1);
    console.log(this.paymentArray);
  }


  placeOrder() {

    if (Object.keys(this.paymentArray).length == 0) {
      this.details_error = "Please select a payment option.";
      return false;
    }

    this.payButtonText = 'Redirecting...';

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount);
    // this.googleEvent.eventEmitter(this.userUnique + ' - ' + this.selected_payment_gateway + ' -Amount- ' + this.paymentArray.amount, "Payment_Checkout", planCodeLabel, this.paymentArray.amount);

    const searchParams = new URLSearchParams();
    Object.keys(this.paymentArray).forEach(key => searchParams.append(key, this.paymentArray[key]));
    console.log(searchParams.toString());

    this.apiService.getData('getpurchaseurlv2?' + searchParams.toString()).subscribe((data: any) => {

      if (data.error_code == '200') {
        console.log(data.result);
        window.location = data.result;


      } else {
        this.details_error = data.error_string;
        this.payButtonText = 'PAY NOW';
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
        this.payButtonText = 'PAY NOW';
      },
      () => {
      }
    );

  }


  applypromoCode() {

    if (this.addPromoForm.invalid) {
      return false;
    }

    let appliedCode = this.addPromoForm.controls.promocode.value;

    // this.googleEvent.eventEmitter(appliedCode, 'Redeem', this.userUnique + ' - Plan Details');

    this.promocode_error = '';
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'couponcode': this.addPromoForm.controls.promocode.value, 'charge_code': '', 'catlogue': this.catlogue ,'platform':'web' });
    this.apiService.postData('activationcode', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log(data);
        if (data.error_code == '200') {
          this.add_promocodesuccess.nativeElement.click(); // show success
          this.addPromoForm.reset();
        } else {
          this.promocode_error = data.error_string;
        }

      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount);
  }
  goToMyacoount() {
    this.closePromoButton.nativeElement.click(); // close the name field modal
    this.router.navigate(['myaccount']);
  }


}
