<video id="player_one" class="video-js vjs-fluid" controls preload="auto" width="640" height="360" playsinline autoplay>
    <!--   autoplay -->


    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
        </a>
    </p>
</video> 


<a data-toggle="modal" data-target="#showError" #showErrorMsg></a>
<div class="modal auth _premium_msg" id="showError">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" #closeShowErrorMsg (click)="removeErrorBox()" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <p>Some technical isses in this video try After some time</p>
        </div>

        <div class="action-btn">
          <div>
            <button type="button" class="btn btn-primary yes" (click)="removeErrorBox()">Ok</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
