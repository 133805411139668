import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { AgGridModule } from '@ag-grid-community/angular';
import { SafeUrlPipe,SortByPipe,BlockCopyPasteDirective, ordinalDate } from '../core';
import { MaterialComponents } from '../core/material-ui';
import { OwlSliderComponent } from './owl-slider/owl-slider.component';  
import { OwlBannerSliderComponent } from './owl-banner-slider/owl-banner-slider.component';  
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AuthModule } from "src/app/views/auth/auth.module";
import { LoginComponent  } from "src/app/views/auth/login/login.component";
import { RegisterComponent  } from "src/app/views/auth/register/register.component";
import { JwVideoPlayerComponent } from "src/app/views/jw-video-player/jw-video-player.component";
import { VerifyotpComponent } from "src/app/views/auth/verifyotp/verifyotp.component";
import { ResendOtpComponent } from 'src/app/views/resend-otp/resend-otp.component';
import { VideojsPlayerComponent } from './videojs-player/videojs-player.component';
import { ImageManipulationComponent } from '../core/components/image-manipulation/image-manipulation.component';
import { EpgsliderComponent } from 'src/app/views/epgslider/epgslider.component';
import { LiveComponent } from './videojs-player/live/live.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssetRowComponent } from '../core/components/asset-row/asset-row.component';
import { ContinueWatchingComponent } from './continue-watching/continue-watching.component';
import { CategoryComponent } from './category/category.component';
import { CampaignBannerComponent } from './campaign-banner/campaign-banner.component';
import { AssetThumbnailComponent } from '../core/components/asset-thumbnail/asset-thumbnail.component';
import { TellUsComponent } from "src/app/views/auth/tell-us/tell-us.component";
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialComponents,
    MatTooltipModule,
    CarouselModule,
    BsDatepickerModule.forRoot(),

  ],
  declarations: [
    SortByPipe,
    BlockCopyPasteDirective,
    SafeUrlPipe, 
    ordinalDate,
    OwlSliderComponent,
    OwlBannerSliderComponent,
    LoginComponent,
    RegisterComponent,
    JwVideoPlayerComponent,
    VerifyotpComponent,
    ResendOtpComponent,
    VideojsPlayerComponent,
    ImageManipulationComponent,
    AssetRowComponent,
    EpgsliderComponent,
    LiveComponent,
    ContinueWatchingComponent,
    CategoryComponent,
    CampaignBannerComponent,
    AssetThumbnailComponent,
    TellUsComponent
	  // SimilarLivetvComponent
  ],
  exports: [
    CommonModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SortByPipe,
    BlockCopyPasteDirective,
    SafeUrlPipe,
    OwlSliderComponent,
    OwlBannerSliderComponent,
    LoginComponent,
    RegisterComponent,
    JwVideoPlayerComponent,
    VerifyotpComponent,
    VideojsPlayerComponent,
    LiveComponent,
    ImageManipulationComponent,
    AssetRowComponent,
    EpgsliderComponent,
    CategoryComponent,
    CampaignBannerComponent,
    // SimilarLivetvComponent,
    AssetThumbnailComponent,
    TellUsComponent
  ],
  providers:[BsDatepickerConfig],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }