import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core';
import { OpenLayoutComponent } from './layout/open-layout/open-layout.component';
import { RegisterComponent } from "./views/auth/register/register.component";
import { DetailEpisodeComponent } from './views/detail-episode/detail-episode.component';
import { HomeComponent } from './views/home/home.component';
import { ViewallComponent } from './views/home/viewall/viewall.component';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { ServerErrorComponent } from './views/server-error/server-error.component';
import { ContactusComponent } from './views/contactus/contactus.component';
import { MediaComponent } from './views/media/media.component';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { AdvertiseComponent } from './views/advertise/advertise.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { DynamicTabComponent } from './views/dynamic-tab/dynamic-tab.component';
import { FaqComponent } from './views/faq/faq.component';

const hostname = 'Hornbill TV ';
export const routes: Routes = [
  // { path: 'nexg', redirectTo: '', pathMatch: 'full' },

  // {
  //   path: '', component: HomeComponent,data: {title:`${hostname}| Home` }
   
  // },
  {
    path: '', component: DynamicTabComponent,data: {title:`${hostname}| Home` }
   
  },
  { 
    path: 'detail/:name/:code/:type', 
    loadChildren: () => import('./views/detail-episode/detail-episode.module').then(m => m.DetailModule),
    data: {
      componentName:"DetailEpisodeComponent"
    }
  },
  { 
    path: 'viewall', 
    loadChildren: () => import('./views/viewall/viewall.module').then(m => m.ViewallModule),
    data: {title: `${hostname}| View All`}
  },

  {
    path: 'livetv',
    loadChildren: () => import('./views/livetv/livetv.module').then(m => m.LivetvModule),
    data: {
      title: `${hostname}| Live Tv`
    }

  },
  {
    path: 'movies',
    loadChildren: () => import('./views/movies/movies.module').then(m => m.MoviesModule),
    data: {title: `${hostname}| Movies`}
  },
  {
    path: 'tvshows',
    loadChildren: () => import('./views/tvshows/tvshow.module').then(m => m.TvshowModule),
    data: {title: `${hostname}| Series`}
  },
  {
    path: 'videos',
      loadChildren: () => import('./views/videos/video.module').then(m => m.VideosModule),
    data: {title: `${hostname}| Videos`}
  },
  {
    path: 'originals',
    loadChildren: () => import('./views/originals/original.module').then(m => m.OriginalsModule),
    data: {title: `${hostname}| Originals`}
  },
  {
    path: 'playlist',
    loadChildren: () => import('./views/playlist/playlist.module').then(m => m.PlaylistModule),
    data: {title: `${hostname}| Watchlist`}
    
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
    data: {title: `${hostname}| My Account`}
  },
  {
    path: 'search',
    loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule),
    data: {title: `${hostname}| Search`}
  },
  {
    path: 'subscribe/payment',
    loadChildren: () => import('./views/subscribe/payment/payment.module').then(m => m.PaymentModule),
    data: {title: `${hostname}| Payment`}
  },
  {
    path: 'subscribe/choseplan',
    loadChildren: () => import('./views/subscribe/chose-plan/chose-plan.module').then(m => m.ChosePlanModule),
    data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'subscribe/choseplan-v2',
    loadChildren: () => import('./views/subscribe/choose-plan-v2/choose-plan-v2.module').then(m => m.ChoosePlanV2Module),
    data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'subcategory',
    loadChildren: () => import('./views/sub-category-list/sub-category-list.module').then(m => m.SubCategoryListModule),
    // data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'subsubcategory',
    loadChildren: () => import('./views/sub-sub-category-list/sub-sub-category-list.module').then(m => m.SubSubCategoryListModule),
    // data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'auth',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),data: {title: 'Home'}
      }]
  },
  {
    path: 'dashboard',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      }]
  },
  {
    path: 'admin',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      }]
  },
  {
    path: '404',
    component:PagenotfoundComponent,data: {title: '404'}
  },
  {
    path: '500',
    component:ServerErrorComponent,data: {title: '500'}
  },

  {
    path: 'contactus',
    component:ContactusComponent,data: {title: 'Contact us'}
  },
  {
    path: 'media',
    component:MediaComponent,data: {title: 'media'}
  },
  {
    path: 'aboutus',
    component:AboutusComponent,data: {title: 'About us'}
  },
  {
    path: 'policy',
    component:PolicyComponent,data: {title: 'Privacy Policy'}
  },
  {
    path: 'terms',
    component:TermsComponent,data: {title: 'Term & Condition'}
  },
  {
    path: 'faq',
    component:FaqComponent,data: {title: 'FAQs'}
  },
  {
    path: 'advertise',
    component:AdvertiseComponent,data: {title: 'Advertise'}
  },
 

  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  OpenLayoutComponent,
];
