import { OwlOptions } from "ngx-owl-carousel-o";
export const customOptionsForBigSlider: OwlOptions = {
  loop: true,
  center: true,
  dots: false,
  margin: 0,
  nav: true,
  autoplay: true,
  autoplayHoverPause: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 1,
      autoplayTimeout: 10000,
    },
    600: {
      items: 1,
      autoplayTimeout: 4000,
    },
    1000: {
      items: 1,
      autoplayTimeout: 4000,
    },
    1300: {
      items: 1,
      autoplayTimeout: 4000,
    },
  },
};

// live tv days slider

export const customOptionsForDaysSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    769: {
      items: 4,
    },
    992: {
      items: 7,
    },
  },
};

// live tv time slider

export const customOptionsForTimeSlider: OwlOptions = {
  autoWidth: true,
  loop: false,
  dots: false,
  margin: 1,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    769: {
      items: 1,
    },
  },
};

//Demo Data banner
export const customDataForBanner = {
  "error_code": "200",
  "count": "1",
  "total_count": "1",
  "error_string": "Your updated Tab data record is here",
  "banner_result": {
      "totalCount": 2,
      "banner": [
          {
              "order": 1,
              "trailer": [],
              "viewer_rating": {
                  "rating_name": "",
                  "rating_viewer_id": ""
              },
              "_id": "6149b3f97bf373216c4127d5",
              "name": " ",
              "slug": "kung-fu-panda-the-paws-of-destiny",
              "banner_type": "home",
              "tab_id": "60e8247ef64aaaad0f138fdd",
              "status": "1",
              "catlogue": "5f48bc1eb9124f41a16c5712",
              "image": "",
              "asset_mongo_id": "5fb7ac06c8d14569103a1671",
              "asset_name": "",
              "type": "tvshow",
              "genre_name": "",
              "genre_text": "",
              "languages": "",
              "viewerrating_id": "",
              "banner_synopsis": "",
              "synopsis": "",
              "charge_code": "WTKUFUDA01",
              "code": "TSH346",
              "advisory": "",
              "is_adult": "0",
              "content_availability": "Free",
              "is_watchlist_added": true
          },
          {
              "order": 2,
              "trailer": [],
              "viewer_rating": {
                  "rating_name": "",
                  "rating_viewer_id": ""
              },
              "_id": "617a70fecd00ad0d9203091b",
              "name": "",
              "slug": "corporate",
              "banner_type": "home",
              "tab_id": "60e8247ef64aaaad0f138fdd",
              "status": "1",
              "catlogue": "5f48bc1eb9124f41a16c5712",
              "image": "",
              "asset_mongo_id": "5fb36b50c8d1452d9018f4d1",
              "asset_name": "Corporate",
              "type": "tvshow",
              "genre_name": "Comedy",
              "genre_text": "Comedy",
              "languages": "English",
              "viewerrating_id": "",
              "banner_synopsis": "When an insensitive tweet threatens Hampton DeVille's business, Jake and Matt are tasked with firing the employee who posted it.",
              "synopsis": "",
              "charge_code": "WTCORPTE01",
              "code": "TSH333",
              "advisory": "Suitable for all",
              "is_adult": "0",
              "content_availability": "Paid",
              "is_watchlist_added": false
          }
      ]
  },
  "result": [
      {
          "_id": "continue-watching",
          // "category_type": "continue-watching",
          // "category_name": "Continue Watching",
          "tab_slug": "home",
          "category_image": "",
          "category_code": "HCTG008",
          "category_disp_type": "",
          "assets_total_cnt": "6",
          "assets_cnt": "6",
          "assets": [
              {
                  "_id": "5f996b91c8d14511637dc92a",
                  "content_id": "5fc4e4f01a27c52eb9749f76",
                  "update_mongo_date": 1636977020267,
                  "show_id": "5f996b91c8d14511637dc92a",
                  "tvshow_id": {
                      "$oid": "5f996b91c8d14511637dc92a"
                  },
                  "tvseason_id": {
                      "$oid": "5f996d48c8d14535807ec86d"
                  },
                  "genre_text": "Food & Cookery",
                  "no": "1",
                  "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635482975/1635482959.jpg",
                  "code": "TSH312",
                  "description": "",
                  "start": "0",
                  "stop": "32",
                  "duration": "33",
                  "type": "episode",
                  "content_availability": "Free",
                  "asset_cat_type": "recentlywatched",
                  "assets_total_cnt": 1,
                  "name": "Raja Rasoi Aur Andaaz Anokha",
                  "synopsis": "Hosted by chef Ranveer Brar, the show takes you to his retreat - an old world bungalow basking in the laid back vibe of Goa and then along on a ride nostalgia and rediscovery; both personal and culinary.",
                  "is_adult": "no",
                  "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635482975/1635482959.jpg",
                  "tvshow": {
                      "tvshow_name": "Raja Rasoi Aur Andaaz Anokha",
                      "access_code": "TSH312"
                  },
                  "season": {
                      "season_number": "1"
                  }
              },
              {
                  "_id": "5fb4986ec8d14532a71e6fdb",
                  "content_id": "5fc4e4f01a27c52eb9749f76",
                  "update_mongo_date": 1636976513511,
                  "show_id": "5fb4986ec8d14532a71e6fdb",
                  "tvshow_id": {
                      "$oid": "5fb4986ec8d14532a71e6fdb"
                  },
                  "tvseason_id": {
                      "$oid": "5fb4998dc8d14538c701579b"
                  },
                  "genre_text": "Comedy",
                  "no": "1",
                  "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635481199/1635481172.jpg",
                  "code": "TSH338",
                  "description": "",
                  "start": "0",
                  "stop": "175",
                  "duration": "192",
                  "type": "episode",
                  "content_availability": "Free",
                  "asset_cat_type": "recentlywatched",
                  "assets_total_cnt": 2,
                  "name": "2 Broke Girls",
                  "synopsis": "Sassy, streetwise Max works two jobs just to get by, one of which is waiting tables during the night shift at the retro-hip Williamsburg Diner. Sophisticated Caroline is an uptown trust fund princess who's having a run of bad luck that forces her to reluctantly give waitressing a shot. At first, Max sees Caroline as yet another in a long line of inept servers she must cover for, but she's surprised to find that Caroline has as much substance as she does style. When Caroline discovers Max's knack for baking amazing cupcakes, she sees a lucrative future for them, but they first need to raise the start-up money.The series chronicles the lives of two waitresses in their mid-20s (at the start of the series): Max Black (Kat Dennings), the child of a poor working-class mother and an unknown father, and Caroline Channing (Beth Behrs), who was born rich but is now disgraced and penniless because her father, Martin Channing, got caught operating a Bernard Madoff-esque Ponzi scheme. The two work together at a Brooklyn diner, soon becoming roommates and friends while building toward their dream of one day opening a cupcake shop. Among those working with them at the restaurant are their boss, Han Lee (Matthew Moy); Oleg (Jonathan Kite), an upbeat but perverted Ukrainian cook; and Earl (Garrett Morris), a 75-year-old cashier. Also featured starting late in the first season is their neighbor and part-time boss Sophie (Jennifer Coolidge), a Polish immigrant who runs the house-cleaning company Sophie's Choice.",
                  "is_adult": "no",
                  "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635481199/1635481172.jpg",
                  "tvshow": {
                      "tvshow_name": "2 Broke Girls",
                      "access_code": "TSH338"
                  },
                  "season": {
                      "season_number": "1"
                  }
              },
              {
                  "_id": "5facaffac8d145511a434a36",
                  "content_id": "5fc4e4f01a27c52eb9749f76",
                  "update_mongo_date": 1636972940420,
                  "show_id": "5facaffac8d145511a434a36",
                  "tvshow_id": {
                      "$oid": "5facaffac8d145511a434a36"
                  },
                  "tvseason_id": {
                      "$oid": "5facb1eec8d14574ea56a9dc"
                  },
                  "genre_text": "Documentary",
                  "no": "2",
                  "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635483478/1635483453.jpg",
                  "code": "TSH322",
                  "description": "",
                  "start": "0",
                  "stop": "488",
                  "duration": "523",
                  "type": "episode",
                  "content_availability": "Free",
                  "asset_cat_type": "recentlywatched",
                  "assets_total_cnt": 1,
                  "name": "The Family",
                  "synopsis": "The Family is an American documentary web television miniseries that premiered on Netflix on August 9, 2019. The series examines a conservative Christian group—known as the Family or the Fellowship—its history, and investigates its influence on American politics.The series was executive produced by Jeff Sharlet, who previously wrote books about the same organization, including C Street: The Fundamentalist Threat to American Democracy and The Family: The Secret Fundamentalism at the Heart of American Power.A community is rattled when a politician's son, who was presumed murdered years ago, returns home.",
                  "is_adult": "no",
                  "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635483478/1635483453.jpg",
                  "tvshow": {
                      "tvshow_name": "The Family",
                      "access_code": "TSH322"
                  },
                  "season": {
                      "season_number": "1"
                  }
              },
              {
                  "_id": "5fb79417c8d1456c0d34a660",
                  "content_id": "5fc4e4f01a27c52eb9749f76",
                  "update_mongo_date": 1636972712206,
                  "show_id": "5fb79417c8d1456c0d34a660",
                  "tvshow_id": {
                      "$oid": "5fb79417c8d1456c0d34a660"
                  },
                  "tvseason_id": {
                      "$oid": "5fb794e1c8d1456c0d34a663"
                  },
                  "genre_text": "Kids",
                  "no": "2",
                  "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635483552/1635483547.jpg",
                  "code": "TSH345",
                  "description": "",
                  "start": "0",
                  "stop": "108",
                  "duration": "112",
                  "type": "episode",
                  "content_availability": "Free",
                  "asset_cat_type": "recentlywatched",
                  "assets_total_cnt": 2,
                  "name": "The Last Kids on Earth",
                  "synopsis": "Young teenager Jack Sullivan and a group of friends live in a decked-out tree house, playing video games, eating candy, and fighting zombies in the aftermath of a monster apocalypse.The Last Kids on Earth is an American-Canadian children's animation streaming television series, based on the book series of the same name by Max Brallier, that premiered on Netflix on September 17, 2019. A ten-episode second season, or \"Book 2\", titled The Last Kids on Earth and the Zombie Parade, premiered on April 17, 2020. Its third season, or \"Book 3\", was released on October 16, 2020.When zombies and monsters invade his hometown, scrappy 13-year-old Jack Sullivan teams up with his friends to survive the apocalypse; based on the popular book series.",
                  "is_adult": "no",
                  "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635483552/1635483547.jpg",
                  "tvshow": {
                      "tvshow_name": "The Last Kids on Earth",
                      "access_code": "TSH345"
                  },
                  "season": {
                      "season_number": "1"
                  }
              },
              // {
              //     "_id": "5fb387f9c8d1455de157eb63",
              //     "content_id": "5fc4e4f01a27c52eb9749f76",
              //     "update_mongo_date": 1636971401620,
              //     "show_id": "5fb387f9c8d1455de157eb63",
              //     "tvshow_id": {
              //         "$oid": "5fb387f9c8d1455de157eb63"
              //     },
              //     "tvseason_id": {
              //         "$oid": "5fb393c0c8d14514f667603d"
              //     },
              //     "genre_text": "Comedy",
              //     "no": "4",
              //     "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635483261/1635483237.jpg",
              //     "code": "TSH335",
              //     "description": "",
              //     "start": "0",
              //     "stop": "120",
              //     "duration": "135",
              //     "type": "episode",
              //     "content_availability": "Free",
              //     "asset_cat_type": "recentlywatched",
              //     "assets_total_cnt": 1,
              //     "name": "Silicon Valley",
              //     "synopsis": "In the high-tech gold rush of modern Silicon Valley, the people most qualified to succeed are the least capable of handling success. A comedy partially inspired by Mike Judge's own experiences as a Silicon Valley engineer in the late 1980s.Silicon Valley is a region in the southern part of the San Francisco Bay Area in Northern California that serves as a global center for high technology and innovation. It corresponds roughly to the geographical Santa Clara Valley.San Jose is Silicon Valley's largest city, the third-largest in California, and the tenth-largest in the United States; other major Silicon Valley cities include Sunnyvale, Santa Clara, Redwood City, Mountain View, Palo Alto, Menlo Park, and Cupertino. The San Jose Metropolitan Area has the third-highest GDP per capita in the world (after Zurich, Switzerland and Oslo, Norway), according to the Brookings Institution.",
              //     "is_adult": "no",
              //     "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635483261/1635483237.jpg",
              //     "tvshow": {
              //         "tvshow_name": "Silicon Valley",
              //         "access_code": "TSH335"
              //     },
              //     "season": {
              //         "season_number": "1"
              //     }
              // },
              // {
              //     "content_id": "5682cbe8cc95b319a65da88f",
              //     "update_mongo_date": 1636971323534,
              //     "viewer_rating": {
              //         "rating_name": "U",
              //         "rating_viewer_id": "1"
              //     },
              //     "asset_cat_type": "recentlywatched",
              //     "languages": "Hindi",
              //     "_id": "61923332aefbf8933505e564",
              //     "is_adult": "no",
              //     "uid": "611b78c09036e32fd026d949",
              //     "asset_mongo_id": "5f898ddac8d14543171a1e8c",
              //     "cloud_image": "https://res.cloudinary.com/digivive/image/upload/v1635428901/1635428895.jpg",
              //     "code": "MOV4022",
              //     "content_availability": "FREE",
              //     "duration": "144",
              //     "name": "Big Brother",
              //     "start": "0",
              //     "stop": "69",
              //     "synopsis": "Sachidanadan had to go to the juvenile home for committing an unintentional murder. After his release, the recent things that happened to his family, shock Sachidanandan and he has to fight against a powerful drug racket in the city.",
              //     "thumbnail": "https://res.cloudinary.com/digivive/image/upload/v1635428901/1635428895.jpg",
              //     "type": "movie",
              //     "no": "",
              //     "genre_text": "Action",
              //     "remaining_time": "",
              //     "advisory": ""
              // }
          ]
      }
  ]
}

//Demo Data Assets
export const customDataForAssets ={
  "from_cache": "1",
  "error_code": "200",
  "count": "10",
  "total_count": "12",
  "error_string": "Your updated Tab data record is here",
  "result": [
      {
          "_id": "6149b5edb11b984fbf2aae17",
          "category_name": "",
          "before_order": "1",
          "slug": "",
          "tab_id": "6149b391b11b984fbf2aae13",
          "order": "1",
          "thumbnailtypes": "6142f7f8604cf069473ff10d",
          "status": "1",
          "catlogue": "5f48bc1eb9124f41a16c5712",
          "created_at": "1632220653",
          "category_code": "CTG814",
          "created_by": "5661e7f46bddbcf2551407f7",
          "deleted_at": "",
          "directly_asset_associated": "0",
          "updated_at": "1635756660",
          "category_id": "",
          "category_type": "category",
          "category_image": "",
          "sub_category_id": "",
          "app_page": "",
          "url": "",
          "landing_page": "",
          "thumbnail": "",
          "category_disp_type": "",
          "asset": {
              "code": "",
              "type": ""
          },
          "thumbnail_banner_stretched_landscape": "",
          "thumbnail_square": "",
          "thumbnail_portrait": "",
          "thumbnail_stretched_landscape": "",
          "id": "1",
          "assets": [
              {
                  "ZA": "1",
                  "TO": "1",
                  "CL": "1",
                  "CA": "1",
                  "VE": "1",
                  "hours": "00",
                  "LV": "1",
                  "MV": "1",
                  "MO": "1",
                  "BN": "1",
                  "trailer": [],
                  "KG": "1",
                  "GF": "1",
                  "CW": "1",
                  "languages": "Hindi",
                  "MG": "1",
                  "EH": "1",
                  "remaining_time": "143",
                  "JM": "1",
                  "NP": "1",
                  "TJ": "1",
                  "CC": "1",
                  "LI": "1",
                  "AR": "1",
                  "LR": "1",
                  "PY": "1",
                  "type": "movie",
                  "CI": "1",
                  "order": "1",
                  "AW": "1",
                  "LB": "1",
                  "WF": "1",
                  "HU": "1",
                  "thumbnail_stretched_landscape": "",
                  "GM": "1",
                  "PF": "1",
                  "image_stretched_landscape_name": "",
                  "MZ": "1",
                  "KR": "1",
                  "TD": "1",
                  "PT": "1",
                  "DJ": "1",
                  "KM": "1",
                  "GP": "1",
                  "CF": "1",
                  "SV": "1",
                  "QA": "1",
                  "GN": "1",
                  "CM": "1",
                  "MN": "1",
                  "TM": "1",
                  "release_date": "1487874600",
                  "PH": "1",
                  "RS": "1",
                  "CG": "1",
                  "duration": "143",
                  "HN": "1",
                  "PG": "1",
                  "AM": "1",
                  "IQ": "1",
                  "PE": "1",
                  "SS": "1",
                  "CN": "1",
                  "platform": "all",
                  "image_name": "Rangoon.jpg",
                  "TK": "1",
                  "KN": "1",
                  "GD": "1",
                  "code_1": "MOV4120",
                  "TF": "1",
                  "NI": "1",
                  "SO": "1",
                  "UA": "1",
                  "BF": "1",
                  "NF": "1",
                  "ZM": "1",
                  "AL": "1",
                  "MT": "1",
                  "ID": "1",
                  "IT": "1",
                  "SR": "1",
                  "current_program": "",
                  "AI": "1",
                  "synopsis": "A love triangle forms against the backdrop of the Second World War.In 1943, as the Second World War rages on, India is fighting a war of her own: independence from British rule. Subash Chandra Bose, founder of the Indian National Army (INA), has become disenchanted with Mahatma Gandhi's Ahimsa (non-violent) movement and desires to fight fire with fire. Recognizing the growing dominance of Imperial Japanese Army in the Indo-China region, the INA allies itself with the Japanese, hoping to ride the wave to Delhi. Indian troops in the British Indian Army are locked in pitched battles with both the INA and its Japanese contingent. In one such skirmish close to the Indo-Burma border, Jemadar Nawab Malik (Shahid Kapoor) and a group of fellow soldiers are cornered by the Japanese infantry. Running to escape on a barge, he is shot and captured as a prisoner of war. Meanwhile, the INA's top brass desperately seeks finances to replenish its heavy ammunition to aid the charge to Delhi.",
                  "ER": "1",
                  "AG": "1",
                  "IM": "1",
                  "asset_mongo_id": "5fa51ed1c8d145328b0d28ef",
                  "GH": "1",
                  "category_code": "CA1002",
                  "epg_id": "",
                  "start": "0",
                  "EE": "1",
                  "min": "02",
                  "code": "MOV4120",
                  "LC": "1",
                  "CH": "1",
                  "play_type": "other",
                  "_id": "618ca0e4bc8ac3133f38c2a4",
                 
                  "category_id": "6149b5edb11b984fbf2aae17",
                 
                  "viewerrating_id": "6053008864dee72bd4e52ebc",
                
                  "image_stretched_landscape_public_id": "1635325186",
                  "SZ": "1",
                  "content_availability": "FREE",
                 
                  "before_order": "5",
                  "MP": "1",
                  "CU": "1",
                  "sub_sub_category_status": "0",
                  "KE": "1",
                  "UZ": "1",
                  "asset_name": "Rangoon",
                  "thumbnail_square": "",
                  "stop": "0",
                  "IS": "1",
                  "name": "Rangoon",
                  "US": "1",
                  "MW": "1",
                  "DK": "1",
                  "UG": "1",
                  "MQ": "1",
                  "SX": "1",
                  "is_adult": "0",
                  "JP": "1",
                  "thumbnail": "",
                  "before_tab_id": "6149b391b11b984fbf2aae13",
                  "IN": "1",
                  "cloud_image": "",
                  "IL": "1",
                  "GE": "1",
                  "TH": "1",
                  "RO": "1",
                  "CR": "1",
                  "catlogue": "5f48bc1eb9124f41a16c5712",
                 
                  "image_square_name": "",
                  "deleted_at": "",
                  "sub_sub_category_id": "0",
                 
                  "genre_name": "Romance",
                  "SJ": "1",
                  "CO": "1",
                  "asset_cat_type": "movie",
                 
                  "category_status": "1",
                  "SI": "1",
                  "sub_category_status": "0",
                  "viewer_rating": {
                      "rating_name": "U",
                      "rating_viewer_id": "1"
                  },
                  "created_by": "5661e7f46bddbcf2551407f7",
                  "PR": "1",
                  "IE": "1",
                  "BZ": "1",
                  "NA": "1",
                  "TV": "1",
                  "CK": "1",
                  "BG": "1",
                  "SE": "1",
                  "FI": "1",
                  "BM": "1",
                  "created_at": "1636606180",
                  "TN": "1",
                  "UM": "1",
                  "BY": "1",
                  "EC": "1",
                  "id": "60",
                  "FR": "1",
                  "PS": "1",
                  "international_rights": "0",
                  "tab_id": "6149b391b11b984fbf2aae13",
                 
                  "thumbnail_portrait": "",
                 
                  "banner_image": "",
                 
                  "image_portrait_name": "",
                  "ML": "1",
                  "image_square_public_id": "1635429891",
                  "ME": "1",
                  "LU": "1",
                  "YT": "1",
                  "sub_category_id": "0",
                  "download_rights": "0",
                  "content_id": "5682cbe8cc95b319a65da88f",
                  "MY": "1",
                  "SB": "1",
                  "MD": "1",
                  "WS": "1",
                  "advisory": "Violence",
                  "banner_synopsis": "A love triangle forms against the backdrop of the Second World War.In 1943, as the Second World War rages on, India is fighting a war of her own: independence from British rule. Subash Chandra Bose, founder of the Indian National Army (INA), has become disenchanted with Mahatma Gandhi's Ahimsa (non-violent) movement and desires to fight fire with fire. Recognizing the growing dominance of Imperial Japanese Army in the Indo-China region, the INA allies itself with the Japanese, hoping to ride the wave to Delhi. Indian troops in the British Indian Army are locked in pitched battles with both the INA and its Japanese contingent. In one such skirmish close to the Indo-Burma border, Jemadar Nawab Malik (Shahid Kapoor) and a group of fellow soldiers are cornered by the Japanese infantry. Running to escape on a barge, he is shot and captured as a prisoner of war. Meanwhile, the INA's top brass desperately seeks finances to replenish its heavy ammunition to aid the charge to Delhi.",
                  "RE": "1",
                  "image_portrait_public_id": "1635405882",
                  "JO": "1",
                  "TR": "1",
                  "image_public_id": "1635429884",
                  "HT": "1",
                  "LY": "1",
                  "genre_text": "Romance",
                  "TZ": "1",
                  "SH": "1",
                  "MF": "1",
                  "charge_code": "WTROMCE06",
                  "MU": "1",
                  "CX": "1",
                  "SD": "1",
                  "TG": "1",
                  "GQ": "1",
                  "image": "",
                  "CV": "1",
                  "category_order": "1"
              },
              {
                  
                  "hours": "00",
                 
                  "trailer": [],
                
                  "languages": "English",
                  "MG": "1",
                  "EH": "1",
                  "remaining_time": "145",
                 
                  "type": "movie",
                  "CI": "1",
                  "order": "2",
                 
                  "thumbnail_stretched_landscape": "",
                  "GM": "1",
                  "PF": "1",
                  "image_stretched_landscape_name": "",
                 
                  "release_date": "1230143400",
                  "PH": "1",
                  "RS": "1",
                  "CG": "1",
                  "duration": "145",
                        "platform": "all",
                  "image_name": "The_Curious_Case_Of_Benjamin_Button.jpg",
                  "TK": "1",
                  "KN": "1",
                  "GD": "1",
                  "code_1": "MOV4117",
                
                  "current_program": "",
                  "AI": "1",
                  "synopsis": "On the day that Hurricane Katrina hits New Orleans, elderly Daisy Williams (nee Fuller) is on her deathbed in a New Orleans hospital. At her side is her adult daughter, Caroline. Daisy asks Caroline to read to her aloud the diary of Daisy's lifelong friend, Benjamin Button. Benjamin's diary recounts his entire extraordinary life, the primary unusual aspect of which was his aging backwards, being diagnosed with several aging diseases at birth and thus given little chance of survival, but who does survive and gets younger with time. Abandoned by his biological father, Thomas Button, after Benjamin's biological mother died in childbirth, Benjamin was raised by Queenie, a black woman and caregiver at a seniors home. Daisy's grandmother was a resident at that home, which is where she first met Benjamin. Although separated through the years, Daisy and Benjamin remain in contact throughout their lives, reconnecting in their forties when in age they finally match up.",
                  "ER": "1",
                  "AG": "1",
                  "IM": "1",
                  "asset_mongo_id": "5fa51ec4c8d145328b0d28b9",
                  "GH": "1",
                  "category_code": "CA089",
                  "AE": "1",
                  "CZ": "1",
                  "NU": "1",
                  "MM": "1",
                  "RU": "1",
                  "CD": "1",
                  "epg_id": "",
                  "start": "0",
                  "EE": "1",
                  "min": "02",
                  "code": "MOV4117",
                  "LC": "1",
                  "CH": "1",
                  "play_type": "other",
                  "_id": "614ad99d42f9810d042b2aba",
                  "OM": "1",
                  "CY": "1",
                  "sec": "25",
                  "GU": "1",
                  "AO": "1",
                  "ES": "1",
                  "status": "1",
                  "AF": "1",
                  "category_id": "6149b5edb11b984fbf2aae17",
                
                  "viewerrating_id": "",
                  
                  "image_stretched_landscape_public_id": "1635325566",
                  "SZ": "1",
                  "content_availability": "FREE",
                  
                  "before_order": "2",
                  
                  "asset_name": "The Curious Case Of Benjamin Button",
                  "thumbnail_square": "",
                  "stop": "0",
                  "IS": "1",
                  "name": "The Curious Case Of Benjamin Button",
                 
                  "is_adult": "0",
                  "JP": "1",
                  "thumbnail": "",
                  "before_tab_id": "6149b391b11b984fbf2aae13",
                  "IN": "1",
                  "cloud_image": "",
                  "IL": "1",
                  "GE": "1",
                  "TH": "1",
                  "RO": "1",
                  "CR": "1",
                  "catlogue": "5f48bc1eb9124f41a16c5712",
                  "LK": "1",
                  "BO": "1",
                  "KP": "1",
                  "IR": "1",
                  "NE": "1",
                  "PK": "1",
                  "TL": "1",
                  "TW": "1",
                  "PM": "1",
                  "NL": "1",
                  "image_square_name": "",
                  "deleted_at": "",
                  "sub_sub_category_id": "0",
                  "AD": "1",
                  "NO": "1",
                  "TT": "1",
                  "SY": "1",
                  "AZ": "1",
                  "KH": "1",
                  "DO": "1",
                  "LA": "1",
                  "BB": "1",
                  "ET": "1",
                  "AS": "1",
                  "FO": "1",
                  "DM": "1",
                  "DZ": "1",
                  "MH": "1",
                  "SK": "1",
                  "genre_name": "Romance",
                  "SJ": "1",
                  "CO": "1",
                  "asset_cat_type": "movie",
                  "NG": "1",
                  "BS": "1",
                  "AT": "1",
                  "GR": "1",
                  "NZ": "1",
                  "SC": "1",
                  "FM": "1",
                  "NC": "1",
                  "BW": "1",
                  "TC": "1",
                  "category_status": "1",
                  "SI": "1",
                  "viewer_rating": {
                      "rating_name": "U",
                      "rating_viewer_id": "1"
                  },
                  "created_by": "5661e7f46bddbcf2551407f7",
                  "PR": "1",
                  "IE": "1",
                  "BZ": "1",
                  "NA": "1",
                  "TV": "1",
                  "CK": "1",
                  "BG": "1",
                  "SE": "1",
                  "FI": "1",
                  "BM": "1",
                  "created_at": "1632295325",
                  "TN": "1",
                  "UM": "1",
                  "BY": "1",
                  "EC": "1",
                  "id": "90",
                  "FR": "1",
                  "PS": "1",
                  "international_rights": "0",
                  "tab_id": "6149b391b11b984fbf2aae13",
                  "SM": "1",
                  "BA": "1",
                  "AX": "1",
                  "GB": "1",
                  "IO": "1",
                  "ZW": "1",
                  "VN": "1",
                  "GA": "1",
                  "thumbnail_portrait": "",
                  "BJ": "1",
                  "MS": "1",
                  "VI": "1",
                  "UY": "1",
                  "XK": "1",
                  "banner_image": "",
                  "SA": "1",
                  "AU": "1",
                  "HK": "1",
                  "PA": "1",
                  "BI": "1",
                  "FK": "1",
                  "BD": "1",
                  "EG": "1",
                  "KZ": "1",
                  "RW": "1",
                  "HR": "1",
                  "MC": "1",
                  "LT": "1",
                  "image_portrait_name": "",
                  "ML": "1",
                  "image_square_public_id": "1635430144",
                  "ME": "1",
                  "LU": "1",
                  "YT": "1",
                  "sub_category_id": "0",
                  "download_rights": "0",
                  "content_id": "5682cbe8cc95b319a65da88f",
                  "MY": "1",
                  "SB": "1",
                  "MD": "1",
                  "WS": "1",
                  "advisory": "Suitable for all",
                  "RE": "1",
                  "image_portrait_public_id": "1635406032",
                  "JO": "1",
                  "TR": "1",
                  "image_public_id": "1635430139",
                  "HT": "1",
                  "PW": "1",
                  "BL": "1",
                  "BH": "1",
                  "KW": "1",
                  "KI": "1",
                  "LY": "1",
                  "genre_text": "Romance",
                  "TZ": "1",
                  "SH": "1",
                  "MF": "1",
                  "charge_code": "WTROMCE07",
                  "MU": "1",
                  "CX": "1",
                  "SD": "1",
                  "TG": "1",
                  "GQ": "1",
                  "image": "",
                  "CV": "1",
                  "category_order": "1"
              },
              {
                  
                  "hours": "00",
                  "LV": "1",
                  "MV": "1",
                  "MO": "1",
                  "BN": "1",
                  "trailer": [],
                  "KG": "1",
                  "GF": "1",
                  "CW": "1",
                  "languages": "Hindi",
                  "MG": "1",
                  "EH": "1",
                  "remaining_time": "144",
                
                  "type": "movie",
                  "CI": "1",
                  "order": "3",
                  "AW": "1",
                  "LB": "1",
                  "WF": "1",
                  "HU": "1",
                  "thumbnail_stretched_landscape": "",
                  "GM": "1",
                  "PF": "1",
                  "image_stretched_landscape_name": "",
                
                  "release_date": "1536863400",
                  "PH": "1",
                  "RS": "1",
                  "CG": "1",
                  "duration": "144",
                
                  "platform": "all",
                  "image_name": "Manmarziyaan.jpg",
                  "TK": "1",
                  "KN": "1",
                  "GD": "1",
                  "code_1": "MOV4147",
                 
                  "current_program": "",
                  "AI": "1",
                  "synopsis": "Rumi and Vicky are love birds where Rumi convinces her parents to let her marry her love, while Vicky is commitment phobic. In comes Robbie, a much well-settled NRI who falls for Rumi. Whom will she decide to settle down with forms the rest of the film.Rumi (Taapsee Pannu), a young woman, is in love with Vicky (Vicky Kaushal), a part-time DJ. One day they are caught by Rumi’s family and pressure builds for them to get married. Rumi promises her family that Vicky will come with his parents to ask for her hand in marriage and if he doesn't show up, she will marry whomever her family chooses. However, nervous and immature, Vicky repeatedly gets cold feet.",
                  "ER": "1",
                  "AG": "1",
                  "IM": "1",
                  "asset_mongo_id": "5fa51f4dc8d145328b0d2aca",
                  "GH": "1",
                  "category_code": "CA090",
                
                  "epg_id": "",
                  "start": "0",
                  "EE": "1",
                  "min": "02",
                  "code": "MOV4147",
                  "LC": "1",
                  "CH": "1",
                  "play_type": "other",
                  "_id": "614ad9a9b7834a24a86e95b7",
                
                  "status": "1",
                  "AF": "1",
                  "category_id": "6149b5edb11b984fbf2aae17",
                
                  "viewerrating_id": "",
                  
                  "image_stretched_landscape_public_id": "1635320322",
                  "SZ": "1",
                  "content_availability": "FREE",
                
                  "before_order": "3",
                  "MP": "1",
               
                  "UZ": "1",
                  "asset_name": "Manmarziyaan",
                  "thumbnail_square": "",
                  "stop": "0",
                  "IS": "1",
                  "name": "Manmarziyaan",
                
                  "is_adult": "0",
                  "JP": "1",
                  "thumbnail": "",
                  "before_tab_id": "6149b391b11b984fbf2aae13",
                  "IN": "1",
                  "cloud_image": "",
                  "IL": "1",
                
                  "catlogue": "5f48bc1eb9124f41a16c5712",
               
                  "image_square_name": "",
                  "deleted_at": "",
                  "sub_sub_category_id": "0",
                 
                  "genre_name": "Romance",
                  "SJ": "1",
                  "CO": "1",
                  "asset_cat_type": "movie",
                  
                  "category_status": "1",
                  "SI": "1",
                  "viewer_rating": {
                      "rating_name": "U",
                      "rating_viewer_id": "1"
                  },
                  "created_by": "5661e7f46bddbcf2551407f7",
                  
                  "created_at": "1632295337",
                  "TN": "1",
                
                  "PS": "1",
                  "international_rights": "0",
                  "tab_id": "6149b391b11b984fbf2aae13",
                 
                  "GA": "1",
                  "thumbnail_portrait": "",
                  "BJ": "1",
                  
                  "banner_image": "",
                  "SA": "1",
                  
                 
                  "image_portrait_name": "",
                  "ML": "1",
                  "image_square_public_id": "1635429615",
                  "ME": "1",
                  "LU": "1",
                  "YT": "1",
                  "sub_category_id": "0",
                  "download_rights": "0",
                  "content_id": "5682cbe8cc95b319a65da88f",
                 
                  "advisory": "Suitable for all",
                  "RE": "1",
                  "image_portrait_public_id": "1635405733",
                  "JO": "1",
                  "TR": "1",
                  "image_public_id": "1635429609",
                
                  "genre_text": "Romance",
                  "TZ": "1",
                  "SH": "1",
                  "MF": "1",
                  "charge_code": "WTROMCE05",
                  
                  "image": "",
                  "CV": "1",
                  "category_order": "1"
              },
              {
                  
                "hours": "00",
                "LV": "1",
                "MV": "1",
                "MO": "1",
                "BN": "1",
                "trailer": [],
                "KG": "1",
                "GF": "1",
                "CW": "1",
                "languages": "Hindi",
                "MG": "1",
                "EH": "1",
                "remaining_time": "144",
              
                "type": "movie",
                "CI": "1",
                "order": "3",
                "AW": "1",
                "LB": "1",
                "WF": "1",
                "HU": "1",
                "thumbnail_stretched_landscape": "",
                "GM": "1",
                "PF": "1",
                "image_stretched_landscape_name": "",
              
                "release_date": "1536863400",
                "PH": "1",
                "RS": "1",
                "CG": "1",
                "duration": "144",
              
                "platform": "all",
                "image_name": "Manmarziyaan.jpg",
                "TK": "1",
                "KN": "1",
                "GD": "1",
                "code_1": "MOV4147",
               
                "current_program": "",
                "AI": "1",
                "synopsis": "Rumi and Vicky are love birds where Rumi convinces her parents to let her marry her love, while Vicky is commitment phobic. In comes Robbie, a much well-settled NRI who falls for Rumi. Whom will she decide to settle down with forms the rest of the film.Rumi (Taapsee Pannu), a young woman, is in love with Vicky (Vicky Kaushal), a part-time DJ. One day they are caught by Rumi’s family and pressure builds for them to get married. Rumi promises her family that Vicky will come with his parents to ask for her hand in marriage and if he doesn't show up, she will marry whomever her family chooses. However, nervous and immature, Vicky repeatedly gets cold feet.",
                "ER": "1",
                "AG": "1",
                "IM": "1",
                "asset_mongo_id": "5fa51f4dc8d145328b0d2aca",
                "GH": "1",
                "category_code": "CA090",
              
                "epg_id": "",
                "start": "0",
                "EE": "1",
                "min": "02",
                "code": "MOV4147",
                "LC": "1",
                "CH": "1",
                "play_type": "other",
                "_id": "614ad9a9b7834a24a86e95b7",
              
                "status": "1",
                "AF": "1",
                "category_id": "6149b5edb11b984fbf2aae17",
              
                "viewerrating_id": "",
                
                "image_stretched_landscape_public_id": "1635320322",
                "SZ": "1",
                "content_availability": "FREE",
              
                "before_order": "3",
                "MP": "1",
             
                "UZ": "1",
                "asset_name": "Manmarziyaan",
                "thumbnail_square": "",
                "stop": "0",
                "IS": "1",
                "name": "Manmarziyaan",
              
                "is_adult": "0",
                "JP": "1",
                "thumbnail": "",
                "before_tab_id": "6149b391b11b984fbf2aae13",
                "IN": "1",
                "cloud_image": "",
                "IL": "1",
              
                "catlogue": "5f48bc1eb9124f41a16c5712",
             
                "image_square_name": "",
                "deleted_at": "",
                "sub_sub_category_id": "0",
               
                "genre_name": "Romance",
                "SJ": "1",
                "CO": "1",
                "asset_cat_type": "movie",
                
                "category_status": "1",
                "SI": "1",
                "viewer_rating": {
                    "rating_name": "U",
                    "rating_viewer_id": "1"
                },
                "created_by": "5661e7f46bddbcf2551407f7",
                
                "created_at": "1632295337",
                "TN": "1",
              
                "PS": "1",
                "international_rights": "0",
                "tab_id": "6149b391b11b984fbf2aae13",
               
                "GA": "1",
                "thumbnail_portrait": "",
                "BJ": "1",
                
                "banner_image": "",
                "SA": "1",
                
               
                "image_portrait_name": "",
                "ML": "1",
                "image_square_public_id": "1635429615",
                "ME": "1",
                "LU": "1",
                "YT": "1",
                "sub_category_id": "0",
                "download_rights": "0",
                "content_id": "5682cbe8cc95b319a65da88f",
               
                "advisory": "Suitable for all",
                "RE": "1",
                "image_portrait_public_id": "1635405733",
                "JO": "1",
                "TR": "1",
                "image_public_id": "1635429609",
              
                "genre_text": "Romance",
                "TZ": "1",
                "SH": "1",
                "MF": "1",
                "charge_code": "WTROMCE05",
                
                "image": "",
                "CV": "1",
                "category_order": "1"
            },
          ],
          "sub_categories": [],
          "thumbnail_type": {},
          "tab_slug": "movies",
          "assets_total_cnt": "4",
          "sub_category_total_count": "0",
          "assets_cnt": "4",
          "sub_category_count": "0"
      },
      
  ]
};
// Demo Data pages
export const customDataForDummyData = {
  error_code: 200,
  is_pack_active: 0,
  catcount: 5,
  error_string: "Your updated Home Category record is here",
  banner_result: {
    totalCount: 2,
    banner: [
      {
        asset_mongo_id: "5fa51f56c8d145328b0d2aef",
        code: "MOV4149",
        image:
          "https://i.pinimg.com/originals/fc/65/27/fc65273a2e94d1f4d0abf34b4db0cc84.jpg",
        banner_synopsis: "",
        data_type: "dummy",
        asset_detail: {
          _id: {
            $oid: "5fa51f56c8d145328b0d2aef",
          },
          type: "movie",
          status: "1",
          charge_code: "WTCOMY11",
          name: "Men In Black 3",
          image:
            "https://res.cloudinary.com/digivive/image/upload/v1604657053/Men_In_Black_3.jpg",
          image_name: "Men_In_Black_3.jpg",
          image_public_id: "Men_In_Black_3",
          hd_size: 26705920,
          sd_size: 13444096,
          synopsis: "",
          hours: "00",
          min: "02",
          sec: "52",
          plateform: "all",
          type_id: ["56ab237ed0944fbf01f1c73d", "5682cc0acc95b319a65da891"],
          content_id: "5682cbe8cc95b319a65da88f",
          categories: ["CTG686"],
          genre: ["56acafd8d0944f2557f1c738"],
          catlogue: ["5f48bc1eb9124f41a16c5712"],
          language: ["569402e7d0944ff7488b4567"],
          is_adult: "no",
          broadcast_id: "591197117f8b9a1c74d583b4",
          rating: "0",
          country: [],
          download_rights: "yes",
          international_rights: "yes",
          director: "Barry Sonnenfeld",
          producer: "G. Mac Brown,Joyce Cox,Paul A. Levin,Laurie MacDonald",
          music_director: "Danny Elfman",
          production_house: "",
          writer: "Etan Cohen, Lowell Cunningham",
          actors: [],
          actor_names: [],
          singers: [],
          release_date: 1337884200,
          keyword_titles: [],
          keywords: [],
          insert_time: {
            $date: {
              $numberLong: "1604656982524",
            },
          },
          default_keyword: [],
          code: "MOV4149",
          priority: {
            default: 1604657308.458072,
          },
          is_pack_assigned: "0",
          singer_names: [],
          category_titles: [""],
          genre_titles: [""],
          language_titles: [""],
          broadcaster_titles: [""],
          priority_menu: {
            "5f48bc1eb9124f41a16c5712": {
              default: 1608812274.475047,
            },
          },
          update_time: {
            $date: {
              $numberLong: "1622443888023",
            },
          },
          n: "Men In Black 3",
          dr: "Barry Sonnenfeld",
          pr: "G. Mac Brown,Joyce Cox,Paul A. Levin,Laurie MacDonald",
          wr: "Etan Cohen, Lowell Cunningham",
          md: "Danny Elfman",
          ph: "",
          k_t: ["Men In Black 3"],
          c_t: [""],
          g_t: [""],
          b_t: ["Youtube"],
          a_n: [
            "Nicole Scherzinger",
            "Josh Brolin",
            "Will Smith",
            "Emma Thompson",
            "David Rasche",
            "Tommy Lee Jones",
            "Jemaine Clement",
            "Michael Stuhlbarg",
            "Mike Colter",
            "Michael Chernus",
            "Alice Eve",
            "Keone Young",
          ],
          s_n: [
            "Pete Anthony",
            "Bill Abbott",
            "Mark Adams",
            "Rick Baptist",
            "Steve Bartek",
          ],
          l_t: ["English"],
          group_ids: [],
          skip_intro: {
            start: "10",
            end: "50",
          },
          cast_intro: {
            start: "100",
            end: "150",
          },
          video_label: [
            {
              label: "skip intro",
              start: "300",
              end: "324",
              is_watch_credit: "0",
            },
          ],
          content_advisory: ["60544bf499eb142828065543"],
          viewerrating_id: "6053008864dee72bd4e52ebf",
        },
        content_availability: "FREE",
        name: "",
        type: "movie",
        charge_code: "WTCOMY11",
        viewerrating_id: "6053008864dee72bd4e52ebf",
        genre_name: "",
        genre_text: "",
        banner_type: "home_b",
        viewer_rating: {
          rating_name: "",
          rating_viewer_id: "",
        },
        advisory: "",
      },
    ],
  },
  result: [
    {
      _id: "5fbcfbcfc8d14520617268ff",
      category_type: "homecategory",
      category_name: "",
      category_image: null,
      category_code: "HCTG046",
      category_disp_type: null,
      assets_total_cnt: 5,
      assets: [
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
      ],
    },
    {
      _id: "5fbcfbcfc8d14520617268ff",
      category_type: "homecategory",
      category_name: "",
      category_image: null,
      category_code: "HCTG046",
      category_disp_type: null,
      assets_total_cnt: 5,
      assets: [
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60190e4f89ead92196263a55",
          category_name: "HCTG046",
          asset_mongo_id: "5fa51f56c8d145328b0d2aef",
          charge_code: "WTCOMY11",
          remaining_time: "",
          stop: "",
          download_rights: "1",
          image_public_id: "",
          release_date: "",
          type: "movie",
          play_type: "other",
          asset_cat_type: "movie",
          content_availability: "FREE",
          duration: "",
          hours: "00",
          min: "02",
          sec: "52",
          code: "MOV4149",
          cloud_image: "",
          is_adult: "no",
          thumbnail: "",
          languages: "",
          genre_text: "",
          name: "",
          current_program: null,
          synopsis: "",
          viewerrating_id: "6053008864dee72bd4e52ebf",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        
      ],
    },
  ],
};

export const customDataForViewAll = {
  error_code: 200,
  last_updated_time: "0",
  error_string: "Your updated Record is here",
  banner_result: {
    totalCount: 3,
    banner: [],
  },
  result: [
    {
      assets: [
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
        {
          _id: "60aca0ebaec3ef24b6672b46",
          type: "movie",
          charge_code: "PFMOV09",
          name: "",
          image:
            "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/v1621926162/1621926142.jpg",
          image_public_id: "1621926142",
          synopsis: "",
          hours: "02",
          min: "05",
          sec: "27",
          content_id: "5682cbe8cc95b319a65da88f",
          genre: ["56acafd8d0944f2557f1c738"],
          language: ["56a28d3dd0944f663f21ce13"],
          is_adult: "no",
          download_rights: "1",
          international_rights: "yes",
          code: "MOV4320",
          is_pack_assigned: "0",
          viewerrating_id: "6053008864dee72bd4e52ebc",
          trailor: "",
          ad_status: "0",
          dur: "02:05:27",
          noOfView: "0",
          languages: "Punjabi",
          genre_text: "",
          content_availability: "FREE",
          video_type: "video",
          thumbnail: "",
          viewer_rating: {
            rating_name: "",
            rating_viewer_id: "",
          },
          advisory: "",
        },
      ],
      category_code: "CTG741",
      category_name: "",
      genre_id: "60518147f8744058cd33cb39",
      assets_total_cnt: 8,
    },
  ],
};

export const episodeDetailSampleData = {
  error_code: 200,
  seasoncnt: 0,
  error_string: "Your updated record is here",
  result: {
    _id: {
      $oid: "60aca2cbc8705e103736d5e7",
    },
    type: "movie",
    charge_code: "PFMOV12",
    name: "",
    image:
      "https://res.cloudinary.com/digivive/image/upload/v1621926663/1621926641.jpg",
    image_public_id: "1621926641",
    synopsis:
      "Sargi is a romantic love triangle involving Rubina Bajwa, Jassi Gill and Babbal Rai. The story starts in Punjab where Jassi Gill is shown to be in love with Rubina since childhood but never had the courage to express his feelings. In order to provide a better living for her family, Rubina decides to do a sham marriage with Karamjit Anmol for immigration purposes and moves abroad with him. Unaware, Jassi was left heart broken. Rubina joins a restaurant run by B N Sharma, whose son Babbal Rai, falls in love with Rubina. When Jassi gets to know about Rubina's sham marriage, he also moves abroad and joins the same restaurant in quest to get his love. Now begins the game of one-upmanship among Jassi, Babbal and Karamjit. Jassi or Babbal, whom does Rubina choose forms the rest of the story.",
    hours: "02",
    min: "03",
    sec: "08",
    type_id: ["56ab237ed0944fbf01f1c73d", "5682cc0acc95b319a65da891"],
    content_id: "5682cbe8cc95b319a65da88f",
    genre: ["569403bbd0944f31498b4567"],
    language: ["56a28d3dd0944f663f21ce13"],
    is_adult: "no",
    download_rights: "1",
    director: "Neeru Bajwa",
    producer: "Neeru Bajwa, Prem Parkash Gupta, Mandeep Singh",
    music_director: "",
    production_house: "",
    writer: "Jagdeep Sidhu",
    actor_names:
      "Karamjit Anmol, Parminder Gill, Jassie Gill, Babbal Rai, Malkeet Rauni, Rubina Bajwa, Harby Sangha, Sukhwinder Chahal, Satwant Kaur, Baninder Bunny, GURPREET BHANGU",
    release_date: "02-12-2018",
    code: "MOV4323",
    singer_names: "Tarun Agrawal",
    viewerrating_id: "6053008864dee72bd4e52ebc",
    channel_code: "",
    bnr_status: "1",
    languages: "Punjabi",
    viewer_rating: {
      rating_name: "U",
      rating_viewer_id: "1",
    },
    advisory: "Suitable for all",
    director_name: "Neeru Bajwa",
    producer_name: "Neeru Bajwa, Prem Parkash Gupta, Mandeep Singh",
    writer_name: "Jagdeep Sidhu",
    music_director_name: "",
    production_house_name: "",
    asset_mongo_id: "60aca2cbc8705e103736d5e7",
    is_watchlist_added: false,
    download_url: {},
    genre_name: "Drama",
    cloud_image:
      "https://res.cloudinary.com/digivive/image/upload/v1621926663/1621926641.jpg",
    thumbnail:
      "https://res.cloudinary.com/digivive/image/upload/v1621926663/1621926641.jpg",
    genre_text: "Drama",
    is_pack_active: 0,
    content_availability: "FREE",
    recently_watched: {
      season: {},
      tvshow: {},
      asset_mongo_id: "60aca2cbc8705e103736d5e7",
      name: "",
      hours: "02",
      min: "03",
      sec: "08",
      languages: ["56a28d3dd0944f663f21ce13"],
      code: "MOV4323",
      charge_code: "PFMOV12",
      content_type: "",
      remaining_time: 7388,
      thumbnail:
        "https://res.cloudinary.com/digivive/image/upload/f_auto,q_auto/1621926641",
      genre_text: "Drama",
      start: "0",
      stop: "0",
      duration: "7388",
      content_availability: "FREE",
      content_id: "5682cbe8cc95b319a65da88f",
      language: ["56a28d3dd0944f663f21ce13"],
      show_id: "",
      season_id: "",
      no: "",
      type: "movie",
      download_rights: "1",
      is_pack_active: 0,
    },
    seasons: [
      {
        _id: "5fb6725bc8d145105e2af870",
        show_id: {
          $oid: "5fb67135c8d145105e2af867",
        },
        season_number: "1",
        season_image: "",
        image_public_id: "1605792298",
        season_episode: 8,
        seasonid: "5fb6725bc8d145105e2af870",
        show_id1: "5fb67135c8d145105e2af867",
        assets: [
          {
            _id: {
              $oid: "5fb672bbc8d1457ed541cf76",
            },
            asset_mongo_id: "5fb672bbc8d1457ed541cf76",
            noOfView: 102,
            type: "episode",
            season_id: "5fb6725bc8d145105e2af870",
            season_number: "",
            show_id: "5fb67135c8d145105e2af867",
            no: "",
            image: "",
            name: "",
            content_id: "5682c9e5cc95b319a65da888",
            synopsis: "",
            hours: "",
            min: "",
            sec: "",
            broadcast_time: "2015-01-15",
            download_rights: "1",
            code: "EPI17709",
            type_id: ["56a0b6f3d0944f383a21ce0f"],
            trailor: "",
            ad_status: "0",
            genre: ["572ae46d7f8b9ac706760c11"],
            charge_code: "WTJUSTGIC01",
            release_date: "",
            thumbnail: "",
            content_availability: "FREE",
            genre_text: "",
            languages: "",
            download_url: {
              hd_url:
                "http://digi-storage2.s3.amazonaws.com/content/digi/demo/Just_Add_Magic_720p.mp4",
              sd_url:
                "http://digi-storage2.s3.amazonaws.com/content/digi/demo/Just_Add_Magic_360p.mp4",
              hd_size: 13429760,
              sd_size: 7961600,
            },
            seek_bar_data: {
              code: "",
              start: "",
              stop: "",
              duration: "",
            },
          },
        ],
      },
    ],

    share_url:
      "https://share.platform8.tv/index.php?code=MOV4323&type=movie&cid=60508372dabf5464540a2460",
    shorten_url: "https://cutt.ly/bnjXYRA",
  },
};

export const recommendedSampleDataNotLive = {
  error_code: "200",
  count: 10,
  last_updated_time: "0",
  error_string: "Your updated live record is here",
  result: [
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "movie",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "movie",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "movie",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "movie",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "movie",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
  ],
};

export const recommendedSampleDataForLive = {
  error_code: "200",
  count: 10,
  last_updated_time: "0",
  error_string: "Your updated live record is here",
  result: [
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
    {
      _id: "606ef08cf8744058cd33cf27",
      charge_code: "PFDULT01",
      hd_size: 841460000,
      sd_size: 460455000,
      name: "",
      synopsis: "",
      hours: "01",
      min: "46",
      sec: "38",
      release_date: 1621535400,
      is_adult: "no",
      download_rights: "yes",
      international_rights: "yes",
      language: ["56a28d3dd0944f663f21ce13"],
      type_id: ["56ab237ed0944fbf01f1c73d"],
      content_id: "5682cc44cc95b319a65da894",
      country: [],
      catlogue: ["60508372dabf5464540a2460"],
      broadcast_id: "606ee42e9e0f70126c12b466",
      viewerrating_id: "6053008864dee72bd4e52ebc",
      plateform: "all",
      rating: "",
      categories: ["CTG743"],
      genre: ["569403bbd0944f31498b4567"],
      director: "Minar Malhotra",
      producer: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      writer: "Minar Malhotra",
      music_director: "Laddi Gill",
      production_house: "",
      keywords: [
        "585b620a7f8b9aff078b4567",
        "5b4c74b088abf2ef0dc65e35",
        "5d3fd0b6c8d1450d6935b0e4",
      ],
      actors: [
        "586dfa337f8b9ab77e1f09f0",
        "586dfa417f8b9ab77e1f0a32",
        "586dfa337f8b9ab77e1f09ed",
        "5d3820acc8d145085e1069f7",
        "5d380753c8d145250b052319",
        "5d3fd0cbc8d1450d6935b10e",
        "5d3fd0cbc8d1450d6935b10d",
      ],
      singers: ["5f9f9b49c8d14518b96b59c8"],
      is_pack_assigned: "1",
      default_keyword: [
        "Dulla Bhatti",
        "Having witnessed his father\\'s murder as a child, a man seeks revenge against the ruthless landlord who killed him.A child, who witnesses his father\\'s brutal murder at the hands of a cruel landlord, decides to take revenge when he grows up.",
        "PFDULT01",
        "Minar Malhotra",
        "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
        "Laddi Gill",
        "Lokdhun",
        "Punjabi",
        "Drama",
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Mani Dhaliwal",
        "PunjabiMovie",
        "Regional Moviee",
      ],
      actor_names: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      singer_names: ["Laddi Gill"],
      keyword_titles: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      category_titles: ["Drama"],
      genre_titles: ["Drama"],
      language_titles: ["Punjabi"],
      broadcaster_titles: ["Lokdhun"],
      viewer_rating: {
        rating_name: "U",
        rating_viewer_id: "1",
      },
      image:
        "https://res.cloudinary.com/digivive/image/upload/v1617883276/1617883102.jpg",
      image_public_id: "1617883102",
      status: "1",
      insert_time: {
        $date: {
          $numberLong: "1617883276622",
        },
      },
      update_time: {
        $date: {
          $numberLong: "1621859129705",
        },
      },
      type: "livetv",
      priority: {
        default: 1622147276.626585,
      },
      priority_menu: {
        "60508372dabf5464540a2460": {
          default: 1626254284.499461,
        },
      },
      code: "MOV4265",
      n: "Dulla Bhatti",
      dr: "Minar Malhotra",
      pr: "Amitansh, Mani Dhaliwal, Naman Gupta, Shakti Manchanda",
      wr: "Minar Malhotra",
      md: "Laddi Gill",
      ph: "",
      k_t: ["Dulla Bhatti", "PunjabiMovie", "Regional Moviee"],
      c_t: ["Drama"],
      g_t: ["Drama"],
      b_t: ["Lokdhun"],
      a_n: [
        "Binnu Dhillon",
        "Aman Hundal",
        "Amar Noorie",
        "Malkeet Rauni",
        "Dev Kharoud",
        "Minar Malhotra",
        "Mani Dhaliwal",
      ],
      s_n: ["Laddi Gill"],
      l_t: ["Punjabi"],
      video_label: [
        {
          label: "skip intro",
          start: "23",
          end: "324",
          is_watch_credit: "1",
        },
        {
          label: "cast",
          start: "11",
          end: "111",
          is_watch_credit: "0",
        },
      ],
      group_ids: ["605b184bac67342bb361a65e"],
      content_availability: "PAID",
      thumbnail: "",
      languages: "",
      genre_text: "",
      advisory: "Suitable for all",
    },
  ],
};

//three sliders
export const customOptionsForThreeMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 1.1,
    },
    600: {
      items: 3,
    },
    // 769: {
    //   items: 3,
    // },
    // 992: {
    //   items: 4,
    // },
    // 1500: {
    //   items: 5,
    // },
  },
};

// Four sliders
export const customOptionsForFourMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 1.1,
    },
    600: {
      items: 2.2,
    },
    769: {
      items: 3,
    },
    992: {
      items: 4,
    },
    // 1500: {
    //   items: 5,
    // },
  },
};

// Five sliders
export const customOptionsForFiveMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 1.5,
    },
    600: {
      items: 2.2,
    },
    769: {
      items: 3.5,
    },
    992: {
      items: 5,
    },
    // 1500: {
    //   items: 5,
    // },
  },
};

//six slider
export const customOptionsForSixMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  items: 6,
  autoWidth: false,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 2.1,
    },
    600: {
      items: 3.2,
    },
    769: {
      items: 4,
    },
    992: {
      items: 6,
    },
    // 1550: {
    //   items: 8,
    // },
  },
};

// Seven slider

export const customOptionsForSevenMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 2.1,
    },
    600: {
      items: 3.2,
    },
    769: {
      items: 5,
    },
    992: {
      items: 7,
    },
    // 1550: {
    //   items: 8,
    // },
  },
};

//Eight slider

export const customOptionsForEightMiniSlider: OwlOptions = {
  loop: false,
  dots: false,
  merge: true,
  margin: 10,
  nav: true,
  navText: [
    "<img src='assets/img/carousel_left.png'>",
    "<img src='assets/img/carousel_right.png'>",
  ],
  responsive: {
    0: {
      items: 2.1,
    },
    600: {
      items: 3.2,
    },
    769: {
      items: 5,
    },
    992: {
      items: 8,
    },
    // 1550: {
    //   items: 8,
    // },
  },
};



export const fourBigSlider = [{}, {}, {}, {}];
export const continueWatching = {
  title: "Continue Watching",
  slides: [
    {
      id: "1",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "2",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "3",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "4",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "5",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "6",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "7",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "8",
      thombUrl: "assets/img/grid_img.jpg",
    },
    {
      id: "9",
      thombUrl: "assets/img/grid_img.jpg",
    },
  ],
};

export const justAdded = {
  title: "Just Added",
  slides: [
    {
      thombUrl: "assets/img/grid_2.jpg",
    },
    {
      thombUrl: "assets/img/grid_2.jpg",
    },
    {
      thombUrl: "assets/img/grid_2.jpg",
    },
    {
      thombUrl: "assets/img/grid_2.jpg",
    },
    {
      thombUrl: "assets/img/grid_2.jpg",
    },
  ],
};
